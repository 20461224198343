import { EcSite } from '../apis/type/ec_site_pb'
import { Config, SiteConfig, Environment } from './index'

export { Environment } from './index'

const defaultSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.NO_TYPE,
  baseUrlRe:
    /^https:\/\/(stailer\.jp|www\.life-netsuper\.jp|(life|itoyokado|fresta)\.admin\.stailer\.jp)\//,
  firebaseConfig: {
    apiKey: 'AIzaSyCHm20SgR5hMK2ioic30E4i3d80i2gduE0',
    authDomain: 'stailer.firebaseapp.com',
    databaseURL: 'https://stailer.firebaseio.com',
    projectId: 'stailer',
    storageBucket: 'stailer.appspot.com',
    messagingSenderId: '175799143842',
    appId: '1:175799143842:web:61f9fd73d8895812c776fb',
    measurementId: 'G-NKFVCVLC2L',
  },
}

const albisSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.ALBIS,
  baseUrlRe: /^https:\/\/albis(|\.admin)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyDYnz_LfHQJ3vqulb7mF7ATs_W-UwgGjsE',
    authDomain: 'stailer-albis.firebaseapp.com',
    databaseURL: 'https://stailer-albis.firebaseio.com',
    projectId: 'stailer-albis',
    storageBucket: 'stailer-albis.appspot.com',
    messagingSenderId: '811975909722',
    appId: '1:811975909722:web:2bbf58bde3a82530b39363',
    measurementId: 'G-9L87HWG900',
  },
}

const yakuodoSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.YAKUODO,
  baseUrlRe: /^https:\/\/yakuodo(|\.admin)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyAkNTTshMFRmy1o-zuhmVQEzLOCpkN4b0o',
    authDomain: 'stailer-yakuodo.firebaseapp.com',
    databaseURL: 'https://stailer-yakuodo.firebaseio.com',
    projectId: 'stailer-yakuodo',
    storageBucket: 'stailer-yakuodo.appspot.com',
    messagingSenderId: '821219558483',
    appId: '1:821219558483:web:5d6b313d39d7bb5e49f2a6',
    measurementId: 'G-2G5XNVVKFW',
  },
}

const createsdSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.CREATESD,
  baseUrlRe: /^https:\/\/createsd(|\.admin)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyC2HZymap1h-HjqXDsWzyzBL139jw2E7TE',
    authDomain: 'stailer-createsd.firebaseapp.com',
    databaseURL: 'https://stailer-createsd.firebaseio.com',
    projectId: 'stailer-createsd',
    storageBucket: 'stailer-createsd.appspot.com',
    messagingSenderId: '349109017964',
    appId: '1:349109017964:web:3b51f7dfb086bbe8682749',
    measurementId: '',
  },
}

const heiwadoSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.HEIWADO,
  baseUrlRe: /^https:\/\/(heiwado(|\.admin)\.stailer\.jp|netsuper\.heiwado\.jp)\//,
  firebaseConfig: {
    apiKey: 'AIzaSyBaERaXYG0XcXlMYjXrNiOPS6WPAdubC3U',
    authDomain: 'stailer-heiwado.firebaseapp.com',
    databaseURL: 'https://stailer-heiwado.firebaseio.com',
    projectId: 'stailer-heiwado',
    storageBucket: 'stailer-heiwado.appspot.com',
    messagingSenderId: '270059842094',
    appId: '1:270059842094:web:a71100e4582043e059cdbf',
    measurementId: '',
  },
}

const sugiSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.SUGI,
  baseUrlRe: /^https:\/\/sugi(|\.admin)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyCJoZaz3kd7KYN0t65mnBUUi4cBz3et6k4',
    authDomain: 'stailer-sugi.firebaseapp.com',
    databaseURL: 'https://stailer-sugi.firebaseio.com',
    projectId: 'stailer-sugi',
    storageBucket: 'stailer-sugi.appspot.com',
    messagingSenderId: '81876590498',
    appId: '1:81876590498:web:986e149c134e7b0111dcfb',
    measurementId: '',
  },
}

const deliciaSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.DELICIA,
  baseUrlRe: /^https:\/\/(delicia(|\.admin)\.stailer\.jp|delicia-netsuper\.jp)\//,
  firebaseConfig: {
    apiKey: 'AIzaSyCXe1vTpRC0piNmpENhhQGbnbmjNhAeSWg',
    authDomain: 'stailer-delicia.firebaseapp.com',
    databaseURL: 'https://stailer-delicia.firebaseio.com',
    projectId: 'stailer-delicia',
    storageBucket: 'stailer-delicia.appspot.com',
    messagingSenderId: '775047228195',
    appId: '1:775047228195:web:2145cc40b82e9b038dba96',
    measurementId: '',
  },
}

const ladydrugSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.LADYDRUG,
  baseUrlRe: /^https:\/\/ladydrug(|\.admin)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyB8vPWJeBmtc2scioq5xrxYxqQLp_D41E8',
    authDomain: 'stailer-ladydrug.firebaseapp.com',
    databaseURL: 'https://stailer-ladydrug.firebaseio.com',
    projectId: 'stailer-ladydrug',
    storageBucket: 'stailer-ladydrug.appspot.com',
    messagingSenderId: '357374349411',
    appId: '1:357374349411:web:376616ce28c1493ec6e856',
    measurementId: 'G-4GGPLS8684',
  },
}

const oginoSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.OGINO,
  baseUrlRe: /^https:\/\/(ogino(|\.admin)\.stailer\.jp|ogino-netsuper\.jp)\//,
  firebaseConfig: {
    apiKey: 'AIzaSyB_XhKWpP3OMilFhhCzbnmnP6NMWO59Nlg',
    authDomain: 'stailer-ogino.firebaseapp.com',
    databaseURL: 'https://stailer-ogino.firebaseio.com',
    projectId: 'stailer-ogino',
    storageBucket: 'stailer-ogino.appspot.com',
    messagingSenderId: '1028906594589',
    appId: '1:1028906594589:web:2da8b8c49f06868d9ff1f4',
    measurementId: 'G-0Q67Z7CXDP',
  },
}

const torisenSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.TORISEN,
  baseUrlRe: /^https:\/\/(torisen(|\.admin)\.stailer\.jp|(|www\.)torisen\.com)\//,
  firebaseConfig: {
    apiKey: 'AIzaSyBZHkrKUsnYpDldeh8z_7wX9IUWDAESvfA',
    authDomain: 'stailer-torisen.firebaseapp.com',
    databaseURL: 'https://stailer-torisen.firebaseio.com',
    projectId: 'stailer-torisen',
    storageBucket: 'stailer-torisen.appspot.com',
    messagingSenderId: '496073903707',
    appId: '1:496073903707:web:d48d94031b63646d0b397e',
    measurementId: 'G-PVZXW24YV4',
  },
}

const seisenichibatopSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.SEISENICHIBATOP,
  baseUrlRe:
    /^https:\/\/(seisenichibatop(|\.admin)\.stailer\.jp|seisenichiba-top\.mamimart-netsuper\.jp)\//,
  firebaseConfig: {
    apiKey: 'AIzaSyAkOhJZ2fdpsuFrVKmu-YPGzYAYPfFifZA',
    authDomain: 'stailer-seisenichibatop.firebaseapp.com',
    databaseURL: 'https://stailer-seisenichibatop.firebaseio.com',
    projectId: 'stailer-seisenichibatop',
    storageBucket: 'stailer-seisenichibatop.appspot.com',
    messagingSenderId: '957463957642',
    appId: '1:957463957642:web:ba04fe5124b57520989d73',
    measurementId: 'G-QDJCSGFHBM',
  },
}

const arukSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.ARUK,
  baseUrlRe: /^https:\/\/(aruk(|\.admin)\.stailer\.jp|aruk-ns\.com)\//,
  firebaseConfig: {
    apiKey: 'AIzaSyCV0lGkzTHosnj4EMGh9qEJa-duH0PvpXs',
    authDomain: 'stailer-aruk.firebaseapp.com',
    databaseURL: 'https://stailer-aruk.firebaseio.com',
    projectId: 'stailer-aruk',
    storageBucket: 'stailer-aruk.appspot.com',
    messagingSenderId: '957369659116',
    appId: '1:957369659116:web:e51c409ee4229ad860bfc9',
    measurementId: 'G-CFD3Q29QRQ',
  },
}

const fujiSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.FUJI,
  baseUrlRe: /^https:\/\/(fuji(|\.admin)\.stailer\.jp|shop\.fujinetsuper\.com)\//,
  firebaseConfig: {
    apiKey: 'AIzaSyDv7CjsOBiLMlcAzEMzaJCzP0hkwSOK1Wk',
    authDomain: 'stailer-fuji.firebaseapp.com',
    databaseURL: 'https://stailer-fuji.firebaseio.com',
    projectId: 'stailer-fuji',
    storageBucket: 'stailer-fuji.appspot.com',
    messagingSenderId: '265678568931',
    appId: '1:265678568931:web:2cef72a8c2e0b6975bf4d6',
    measurementId: 'G-TYQC1M4Y38',
  },
}

const frestaSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.FRESTA,
  baseUrlRe: /^https:\/\/(fresta(|\.admin)\.stailer\.jp|everyday-fresta\.jp)\//,
  firebaseConfig: {
    apiKey: 'AIzaSyCUR4Chap2J1sW3W5bdKqHbbZFbX_sDr0c',
    authDomain: 'stailer-fresta.firebaseapp.com',
    databaseURL: 'https://stailer-fresta.firebaseio.com',
    projectId: 'stailer-fresta',
    storageBucket: 'stailer-fresta.appspot.com',
    messagingSenderId: '51244362086',
    appId: '1:51244362086:web:3c9a5757a708e6ac6780a7',
    measurementId: 'G-D2F3S44Z6X',
  },
}

const siteConfigMapping: { [domain: string]: SiteConfig } = {
  // パートナー用のドメイン
  'stailer.jp': defaultSiteConfig,
  'albis.stailer.jp': albisSiteConfig,
  'yakuodo.stailer.jp': yakuodoSiteConfig,
  'createsd.stailer.jp': createsdSiteConfig,
  'heiwado.stailer.jp': heiwadoSiteConfig,
  'sugi.stailer.jp': sugiSiteConfig,
  'delicia.stailer.jp': deliciaSiteConfig,
  'ladydrug.stailer.jp': ladydrugSiteConfig,
  'fresta.stailer.jp': frestaSiteConfig,
  'ogino.stailer.jp': oginoSiteConfig,
  'torisen.stailer.jp': torisenSiteConfig,
  'seisenichibatop.stailer.jp': seisenichibatopSiteConfig,
  'aruk.stailer.jp': arukSiteConfig,
  'fuji.stailer.jp': fujiSiteConfig,
  // 10X 社内用のドメイン
  'albis.admin.stailer.jp': albisSiteConfig,
  'yakuodo.admin.stailer.jp': yakuodoSiteConfig,
  'createsd.admin.stailer.jp': createsdSiteConfig,
  'heiwado.admin.stailer.jp': heiwadoSiteConfig,
  'sugi.admin.stailer.jp': sugiSiteConfig,
  'delicia.admin.stailer.jp': deliciaSiteConfig,
  'ladydrug.admin.stailer.jp': ladydrugSiteConfig,
  'itoyokado.admin.stailer.jp': defaultSiteConfig,
  'life.admin.stailer.jp': defaultSiteConfig,
  'fresta.admin.stailer.jp': frestaSiteConfig,
  'ogino.admin.stailer.jp': oginoSiteConfig,
  'torisen.admin.stailer.jp': torisenSiteConfig,
  'seisenichibatop.admin.stailer.jp': seisenichibatopSiteConfig,
  'aruk.admin.stailer.jp': arukSiteConfig,
  'fuji.admin.stailer.jp': fujiSiteConfig,
}

const config: Config = {
  siteConfigMapping,
  apiBaseUrl: '/rpc',
  environment: Environment.production,
}

export default config
